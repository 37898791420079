import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0,11,2,3,5,7,9];

export const dictionary = {
		"/": [12],
		"/account": [~13],
		"/account/update_password": [~14],
		"/auth": [15],
		"/auth/reset_password": [16],
		"/auth/sign_in": [17],
		"/auth/sign_up": [18],
		"/teams": [47,[11]],
		"/teams/[team_id]": [~48,[11]],
		"/t/[team_id]/bots": [~19],
		"/t/[team_id]/bots/new": [30],
		"/t/[team_id]/bots/[bot_id]/config": [20,[2]],
		"/t/[team_id]/bots/[bot_id]/connections": [21,[2,3]],
		"/t/[team_id]/bots/[bot_id]/connections/discord": [22,[2,3,4]],
		"/t/[team_id]/bots/[bot_id]/connections/discord/[conn_id]/conn": [~23,[2,3,4,5]],
		"/t/[team_id]/bots/[bot_id]/connections/discord/[conn_id]/servers": [~24,[2,3,4,5]],
		"/t/[team_id]/bots/[bot_id]/connections/webchat/[conn_id]": [25,[2,3,6,7]],
		"/t/[team_id]/bots/[bot_id]/connections/whatsapp": [26,[2,3,8]],
		"/t/[team_id]/bots/[bot_id]/connections/whatsapp/[conn_id]": [27,[2,3,8,9]],
		"/t/[team_id]/bots/[bot_id]/knowledge": [~28,[2]],
		"/t/[team_id]/bots/[bot_id]/link_knowledge_source": [~29,[2]],
		"/t/[team_id]/conversations": [~31],
		"/t/[team_id]/conversations/bots/[bot_id]": [~33],
		"/t/[team_id]/conversations/[conversation_id]/chat": [~32],
		"/t/[team_id]/knowledge_sources": [~34],
		"/t/[team_id]/knowledge_sources/new": [39],
		"/t/[team_id]/knowledge_sources/new/(new_flow)/database": [~40,[10]],
		"/t/[team_id]/knowledge_sources/new/(new_flow)/document": [~41,[10]],
		"/t/[team_id]/knowledge_sources/new/(new_flow)/faq": [~42,[10]],
		"/t/[team_id]/knowledge_sources/new/(new_flow)/text": [~43,[10]],
		"/t/[team_id]/knowledge_sources/new/(new_flow)/website": [~44,[10]],
		"/t/[team_id]/knowledge_sources/[knowledge_source_id]": [~35],
		"/t/[team_id]/knowledge_sources/[knowledge_source_id]/database": [~36],
		"/t/[team_id]/knowledge_sources/[knowledge_source_id]/edit": [~37],
		"/t/[team_id]/knowledge_sources/[knowledge_source_id]/faq": [~38],
		"/t/[team_id]/tags": [45],
		"/t/[team_id]/tags/training": [~46]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';